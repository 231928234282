import { render, staticRenderFns } from "./HomeMultilista.vue?vue&type=template&id=95f940b6&scoped=true&"
import script from "./HomeMultilista.vue?vue&type=script&lang=js&"
export * from "./HomeMultilista.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95f940b6",
  null
  
)

export default component.exports